<template>
  <div>
    <el-form>
      <table class="tableContainer">
        <caption>{{ programme.project_name }}</caption>
        <tr>
          <th colspan="2">工程信息:</th>
          <th colspan="6">{{ programme.project_id }}</th>
          <th colspan="2">销售单编号:</th>
          <th colspan="2">下单时间:</th>
        </tr>

        <tr>
          <th colspan="2">工程地址:</th>
          <td colspan="6">&nbsp;</td>
          <td colspan="2" rowspan="2"></td>
          <td rowspan="2" colspan="3">{{ programme.created_at | formatDate }}</td>
        </tr>

        <tr>
          <th colspan="2">联系人:</th>
          <td colspan="2">&nbsp;</td>
          <th>联系方式:</th>
          <td colspan="3"></td>
        </tr>

        <tr>
          <td colspan="12">&nbsp;</td>
        </tr>

        <tr>
          <th rowspan="2">序号</th>
          <th rowspan="2">类别</th>
          <th rowspan="2">品牌</th>
          <th rowspan="2">材质名称</th>
          <th rowspan="2">材料型号</th>
          <th colspan="3">截面尺寸(mm)</th>
          <th colspan="2">需求量</th>
          <th rowspan="2">进场时间</th>
          <th rowspan="2">备注</th>
        </tr>

        <tr>
          <th>厚/B</th>
          <th>宽/W</th>
          <th>高/L</th>
          <th>单位</th>
          <th>数量</th>
        </tr>

        <tbody class="td-list" v-for="(row, index) in editRows" :key="index">
          <tr v-if="row.is_delete != 1">
            <td>{{ row.serial_number }}</td>
            <td>
              <el-input
                v-if="!isRead"
                @change="changeData"
                style="width: 140px"
                size="mini"
                v-model="row['类别']"
              />
              <template v-else>
                {{ row['类别'] }}
              </template>
            </td>
            <td>
              <el-input
                v-if="!isRead"
                @change="changeData"
                style="width: 120px"
                size="mini"
                v-model="row['品牌']"
              />
              <template v-else>
                {{ row['品牌'] }}
              </template>
            </td>
            <td>
              <el-input
                v-if="!isRead"
                @change="changeData"
                style="width: 140px"
                size="mini"
                v-model="row['产品名称']"
              />
              <template v-else>
                {{ row['产品名称'] }}
              </template>
            </td>
            <td>
              <el-input
                v-if="!isRead"
                @change="changeData"
                style="width: 160px"
                size="mini"
                v-model="row['材料型号']"
              />
              <template v-else>
                {{ row['材料型号'] }}
              </template>
            </td>

            <template>
              <td>
                <el-input
                  v-if="!isRead"
                  @change="changeData"
                  style="width: 50px"
                  size="mini"
                  v-model="row['厚']"
                />
                <template v-else>
                  {{ row['厚'] }}
                </template>
              </td>
              <td>
                <el-input
                  v-if="!isRead"
                  @change="changeData"
                  style="width: 50px"
                  size="mini"
                  v-model="row['宽']"
                />
                <template v-else>
                  {{ row['宽'] }}
                </template>
              </td>
              <td>
                <el-input
                  v-if="!isRead"
                  @change="changeData"
                  style="width: 50px"
                  size="mini"
                  v-model="row['高']"
                />
                <template v-else>
                  {{ row['高'] }}
                </template>
              </td>
            </template>

            <td>
              <el-input
                v-if="!isRead"
                @change="changeData"
                style="width: 60px"
                size="mini"
                v-model="row['需求单位']"
              />
              <template v-else>
                {{ row['需求单位'] }}
              </template>
            </td>
            <td>
              <el-input
                v-if="!isRead"
                @change="changeData"
                style="width: 60px"
                size="mini"
                v-model="row['需求数量']"
              />
              <template v-else>
                {{ row['需求数量'] }}
              </template>
            </td>
            <td></td>

            <td style="position: relative">
              <div class="floating-div">
                <i @click="addRow" class="icon el-icon-circle-plus-outline"></i>

                <i
                  v-if="row.is_delete == 1"
                  @click="removeRow(index)"
                  class="icon el-icon-top-left"
                />
                <i v-else @click="removeRow(index)" class="icon el-icon-delete"></i>
              </div>
            </td>
          </tr>
        </tbody>

        <tr>
          <td colspan="12">&nbsp;</td>
        </tr>

        <tr>
          <th colspan="3">制单人（签字）：</th>
          <th colspan="2"></th>
          <th colspan="3">审核：</th>
          <th colspan="4"></th>
        </tr>
      </table>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    programme: {
      type: Object,
      default() {
        return {};
      },
    },
    table: {
      type: Object,
      default() {
        return {};
      },
    },
    rows: {
      type: Array,
      default() {
        return [];
      },
    },
    isRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      programmeRows: null,
      editRows: [],
    };
  },
  watch: {
    rows: {
      handler() {
        this.editRows = [];
        this.programmeRows = this.rows;
        for (let row of this.programmeRows) {
          // console.log(row);
          let d = {
            serial_number: row.serial_number,
            id: row.id,
          };
          for (let o of row.prop) {
            // if (o.Name == '截面尺寸') {
            //     d[o.Name] = JSON.parse(o.Value);
            // } else {
            d[o.Name] = o.Value;
            // }
          }
          this.editRows.push(d);
        }
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.programmeRows = this.table.rows;
    for (let row of this.programmeRows) {
      // console.log(row);
      let d = {
        serial_number: row.serial_number,
        id: row.id,
      };
      for (let o of row.prop) {
        // if (o.Name == '截面尺寸') {
        //     d[o.Name] = JSON.parse(o.Value);
        // } else {
        d[o.Name] = o.Value;
        // }
      }
      this.editRows.push(d);
    }
  },
  methods: {
    addRow() {
      let row = this.editRows[0];
      // console.log(row);
      let newRow = {};
      for (let k in row) {
        // if (k == '截面尺寸') {
        //     newRow[k] = ['', ''];
        // } else {
        newRow[k] = '';
        // }
      }
      newRow['serial_number'] = this.editRows.length + 1;
      newRow['id'] = 0;
      this.editRows.push(newRow);
      this.$emit('changeData', this.editRows);
    },
    removeRow(index) {
      let row = this.editRows[index];
      if (row.id == 0) {
        this.editRows.splice(index, 1);
      } else {
        row.is_delete = 1;

        this.$set(this.editRows[index], row);
        this.editRows[index]['is_delete'] = 1;
      }
      this.$emit('changeData', this.editRows);
    },
    changeData() {
      // console.log(arguments);
      this.$emit('changeData', this.editRows);
    },
  },
};
</script>

<style lang="scss" scoped></style>
