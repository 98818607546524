import { render, staticRenderFns } from "./baseTableList.vue?vue&type=template&id=50f7f86c&scoped=true&"
import script from "./baseTableList.vue?vue&type=script&lang=js&"
export * from "./baseTableList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f7f86c",
  null
  
)

export default component.exports