<template>
  <div>
    <el-form>
      <table class="tableContainer">
        <caption>{{ programme.project_name }}</caption>
        <tr>
          <th colspan="2">工程信息:</th>
          <th colspan="9">{{ programme.project_id }}</th>
          <th colspan="4">销售单编号:</th>
          <th colspan="3">下单时间:</th>
        </tr>

        <tr>
          <th colspan="2">工程地址:</th>
          <td colspan="9">&nbsp;</td>
          <td colspan="4" rowspan="2"></td>
          <td rowspan="2" colspan="3">{{ programme.created_at | formatDate }}</td>
        </tr>

        <tr>
          <th colspan="2">联系人:</th>
          <td colspan="2">&nbsp;</td>
          <th colspan="2">联系方式:</th>
          <td colspan="5"></td>
        </tr>

        <tr>
          <td colspan="18">&nbsp;</td>
        </tr>

        <tr>
          <th rowspan="2">序号</th>
          <th rowspan="2">品牌</th>
          <th rowspan="2">产品名称</th>
          <th rowspan="2">产品编号</th>
          <th colspan="3">规格</th>
          <th rowspan="2">单位</th>
          <th rowspan="2">数量</th>
          <th rowspan="2">户数</th>
          <th rowspan="2">总计</th>
          <th colspan="2">需求量</th>
          <th rowspan="2">图示编号</th>
          <th rowspan="2">区域</th>
          <th rowspan="2">工艺要求</th>
          <th rowspan="2">进场时间</th>
          <th rowspan="2">备注</th>
        </tr>

        <tr>
          <th colspan="2">截面尺寸</th>
          <th>长</th>
          <th>单位</th>
          <th>数量</th>
        </tr>

        <template v-for="(group, index) in groupRows">
          <template v-for="(row, i) in group">
            <tbody class="td-list" :key="index + '_' + i">
              <template v-if="row.is_delete != 1">
                <tr class="total-td" :key="i" v-if="row.total">
                  <th colspan="4"></th>
                  <th style="text-align: right" colspan="7">合计:</th>
                  <th></th>
                  <th colspan="3" style="text-align: left">
                    {{ parseFloat(row.total).toFixed(2) }}
                  </th>
                  <th colspan="7"></th>
                </tr>
                <tr v-else :key="i">
                  <td>{{ row.serial_number }}</td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 140px"
                      size="mini"
                      v-model="row['品牌']"
                    />
                    <template v-else>
                      {{ row['品牌'] }}
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 120px"
                      size="mini"
                      v-model="row['产品名称']"
                    />
                    <template v-else>
                      {{ row['产品名称'] }}
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 140px"
                      size="mini"
                      v-model="row['厂商编号']"
                    />
                    <template v-else>
                      {{ row['厂商编号'] }}
                    </template>
                  </td>
                  <td colspan="2">
                    <template v-if="row['截面尺寸']">
                      <el-input
                        v-if="!isRead"
                        @change="changeData"
                        style="width: 160px"
                        size="mini"
                        v-model="row['截面尺寸']"
                      />
                      <template v-else>
                        {{ row['截面尺寸'] }}
                      </template>
                    </template>

                    <template v-else>
                      <el-input
                        v-if="!isRead"
                        @change="changeData"
                        style="width: 55px"
                        size="mini"
                        v-model="row['厚']"
                      />
                      <template v-else>{{ row['厚'] }},</template>

                      <el-input
                        v-if="!isRead"
                        @change="changeData"
                        style="width: 55px"
                        size="mini"
                        v-model="row['宽']"
                      />
                      <template v-else>
                        {{ row['宽'] }}
                      </template>
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 60px"
                      size="mini"
                      v-model="row['长']"
                    />
                    <template v-else>
                      {{ row['长'] }}
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 50px"
                      size="mini"
                      v-model="row['标品单位']"
                    />
                    <template v-else>
                      {{ row['标品单位'] }}
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 60px"
                      size="mini"
                      v-model="row['标品数量']"
                    />
                    <template v-else>
                      {{ row['标品数量'] }}
                    </template>
                  </td>
                  <td>1</td>
                  <td>
                    {{ row['标品数量'] }}
                  </td>

                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 70px"
                      size="mini"
                      v-model="row['需求单位']"
                    />
                    <template v-else>
                      {{ row['需求单位'] }}
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 60px"
                      size="mini"
                      v-model="row['需求数量']"
                    />
                    <template v-else>
                      {{ row['需求数量'] }}
                    </template>
                  </td>
                  <td>
                    <!-- <el-input
                                            @change="changeData"
                                            style="width: 60px"
                                            size="mini"
                                            v-model="row['利用率']"
                                        /> -->
                    {{ row['区域'] }}-DM-{{ i + 1 }}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style="position: relative">
                    <div class="floating-div">
                      <i @click="addRow(row)" class="icon el-icon-circle-plus-outline"></i>

                      <i
                        v-if="row.is_delete == 1"
                        @click="removeRow(row)"
                        class="icon el-icon-top-left"
                      />
                      <i v-else @click="removeRow(row)" class="icon el-icon-delete"></i>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </template>

        <tr>
          <td colspan="18">&nbsp;</td>
        </tr>

        <tr>
          <th colspan="3">制单人（签字）：</th>
          <th colspan="4"></th>
          <th colspan="2">审核：</th>
          <th colspan="96"></th>
        </tr>
      </table>
    </el-form>
  </div>
</template>

<script>
const ProductNumber = '厂商编号';
const NeedCount = '需求数量';
export default {
  props: {
    programme: {
      type: Object,
      default() {
        return {};
      },
    },
    table: {
      type: Object,
      default() {
        return {};
      },
    },
    rows: {
      type: Array,
      default() {
        return [];
      },
    },
    isRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      programmeRows: null,
      editRows: [],
    };
  },
  computed: {
    groupRows() {
      let groupSum = {};
      let groupRows = {};
      for (let rowIndex in this.editRows) {
        let row = this.editRows[rowIndex];
        if (!groupRows[row[ProductNumber]]) {
          groupRows[row[ProductNumber]] = [];
        }

        if (!groupSum[row[ProductNumber]]) {
          groupSum[row[ProductNumber]] = 0;
        }
        groupRows[row[ProductNumber]].push(row);
        groupSum[row[ProductNumber]] += parseFloat(row[NeedCount]);
      }

      for (let key in groupSum) {
        groupRows[key].push({ id: 0, is_delete: 0, total: groupSum[key] });
      }

      return groupRows;
    },
  },
  watch: {
    rows: {
      handler() {
        this.editRows = [];
        this.programmeRows = this.rows;
        for (let rowIndex in this.programmeRows) {
          let row = this.programmeRows[rowIndex];
          let d = {
            serial_number: row.serial_number,
            id: row.id,
            index: rowIndex,
          };

          for (let o of row.prop) {
            if (o.Name == '截面尺寸') {
              try {
                let v = JSON.parse(o.Value);
                o.Value = v;
              } catch (ex) {
                console.log(ex);
              }
            }
            d[o.Name] = o.Value;
          }

          this.editRows.push(d);
        }
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.programmeRows = this.table.rows;
    for (let rowIndex in this.programmeRows) {
      let row = this.programmeRows[rowIndex];
      let d = {
        serial_number: row.serial_number,
        id: row.id,
        index: rowIndex,
      };

      for (let o of row.prop) {
        // if (o.Name == '截面尺寸') {
        // o.Value = JSON.parse(o.Value);
        // }
        d[o.Name] = o.Value;
      }

      this.editRows.push(d);
    }
  },
  methods: {
    addRow(row) {
      let newRow = {};
      for (let k in row) {
        if (k == ProductNumber) {
          newRow[k] = row[k];
        } else {
          newRow[k] = '';
        }
      }
      newRow[NeedCount] = 0;
      newRow['serial_number'] = this.editRows.length + 1;
      newRow['id'] = 0;
      newRow['index'] = this.editRows.length;

      this.editRows.push(newRow);
      this.$emit('changeData', this.editRows);
    },
    removeRow(row) {
      let index = parseInt(row.index);

      if (row.id == 0) {
        this.editRows.splice(index, 1);
      } else {
        row.is_delete = 1;

        this.$set(this.editRows[index], row);
        this.editRows[index]['is_delete'] = 1;
      }
      this.$emit('changeData', this.editRows);
    },
    changeData() {
      this.$emit('changeData', this.editRows);
    },
  },
};
</script>

<style lang="scss" scoped></style>
