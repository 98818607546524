import $http from '@/utils/http';

/**
 * 案例管理列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function programmeList(param) {
  return $http.get('/v2/programme/search', param);
}

export function programmeGetRows(data) {
  return $http.get('/v2/programme/get_rows', data);
}

export function programmeProjectType() {
  return $http.get('/v2/programme/project_type');
}
export function programmeExportGetFlags() { 
  return $http.post('/v2/programme/export_get_sp_flags');
}

export function programmeInfo(id) {
  return $http.get('/v2/programme/get', { id });
}

export function programmeSave(data) {
  return $http.post('/v2/programme/save', data);
}

export function programmeExport(data, filename) {
  return $http.exports('/v2/programme/export_xlsx', data, 'loadingDiv', filename);
}

export function programmeExportAll(data, filename) {
  return $http.exports('/v2/programme/export_all', data, 'loadingDiv', filename);
}

export function programmeExportSpecial(data, filename) {
  return $http.exports('/v2/programme/export_special', data, 'loadingDiv', filename);
}

