<template>
  <div class="container" v-if="programme">
    <h3>
      案例: {{ programme.project_id }}
      <el-button @click="exportAll" size="mini" type="primary">导出</el-button>
      <el-button v-if="isQDD" @click="exportSpecial" size="mini" type="primary">
        导出墙顶地
      </el-button>
      <el-button v-if="isHZExcel" @click="exportHZSpecial" size="mini" type="warning">
        导出清单
      </el-button>
    </h3>
    <el-tabs type="card" size="mini" v-model="activeName">
      <el-tab-pane
        class="tab-pane"
        :label="projectType[table.project_type] + '-' + table.table_name"
        v-for="(table, index) in programme.tables"
        :name="'tab_' + index"
        :key="index"
      >
        <tableContainer
          v-if="'tab_' + index == activeName"
          :id="id"
          :programme="programme"
          :table="table"
          :isLock="isLock"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  programmeInfo,
  programmeProjectType,
  programmeExportAll,
  programmeExportSpecial,
  programmeExportGetFlags,
} from '@/common/programme.js';
import tableContainer from './components/tableContainer.vue';
import dayjs from 'dayjs';

export default {
  components: { tableContainer },
  data() {
    return {
      activeName: '',
      projectType: {},
      programme: null,
      id: null,
      sp: null,
      isLock: false,
    };
  },
  computed: {
    isQDD() {
      const project_types = this.programme.project_types.split(',');

      return (
        project_types.includes('1') && project_types.includes('3') && project_types.includes('5')
      );
    },
    isHZExcel() {
      return this.sp == 'hz';
    }
  },
  async created() {
    const loading = this.$loading({
      lock: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.2)',
    });
    this.sp = this.$store.getters.get_export_sp();
    this.id = this.$route.query.id;
    this.isLock = this.$route.meta.isLock == 1 ? true : false;

    if (this.sp == null) { 
      programmeExportGetFlags().then((res) => { 
        if (res.code == 200) { 
          if (res.result && res.result.sp != undefined) {
            this.$store.dispatch('SET_EXPORT_SP', {
              sp: res.result.sp
            }).catch((ex) => {
              console.log(ex);
            });
            this.sp = res.result.sp;
          }
        }
      });
    }

    resp = await programmeProjectType();
    for (let r of resp.result) {
      this.projectType[r.id] = r.project_name;
    }

    let resp = await programmeInfo(this.id);
    // console.log(resp);
    this.programme = resp.result;
    this.activeName = 'tab_0';
    loading.close();
  },
  methods: {
    // changeData(val) {
    //     this.editRows = val;
    // },
    exportAll() {
      let params = {
        id: this.programme.id,
      };
      programmeExportAll(params, 'export_' + dayjs().format('YYYYMMDDTHHmmss') + '.xlsx');
    },

    exportSpecial() {
      let params = {
        id: this.programme.id,
      };
      programmeExportSpecial(params, 'export_' + dayjs().format('YYYYMMDDTHHmmss') + '.xlsx');
    },
    exportHZSpecial() {
      let params = {
        id: this.programme.id,
        sp: 'hz',
      };
      programmeExportSpecial(params, 'export_hz_' + dayjs().format('YYYYMMDDTHHmmss') + '.xlsx');
    }
  },
};
</script>

<style lang="scss">
.container {
  .el-tabs__header {
    margin: 0 0 0px !important;
  }
  .el-tabs__nav {
    // background-color: #8f4040 !important;
    .is-active {
      background-color: #fff !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.tab-pane {
  background-color: #fff;
}
</style>
