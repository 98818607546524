<template>
  <div>
    <table class="tableContainer">
      <caption>{{ programme.project_name }}</caption>

      <tr>
        <th colspan="10">产品价格清单</th>
        <th colspan="4"></th>
      </tr>

      <tr>
        <th rowspan="5">基本尺寸</th>
        <th colspan="3">整体浴室尺寸</th>
        <th colspan="2">门洞尺寸</th>
        <th colspan="2">窗洞尺寸</th>
        <th colspan="2">管井尺寸</th>
        <th rowspan="5">图纸信息</th>
        <th rowspan="5" colspan="3"></th>
      </tr>

      <tr>
        <th>长</th>
        <th>宽</th>
        <th>高</th>
        <th>宽</th>
        <th>高</th>
        <th>宽</th>
        <th>高</th>
        <th>长</th>
        <th>宽</th>
      </tr>

      <tr>
        <td rowspan="3" v-if="baseRow">{{ parseFloat(baseRow['长']).toFixed(2) }}</td>
        <td rowspan="3" v-if="baseRow">
          {{ parseFloat(baseRow['宽']).toFixed(2) }}
        </td>
        <td rowspan="3" v-if="baseRow">{{ parseFloat(baseRow['高']).toFixed(2) }}</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>

      <tr>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>

      <tr>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>

      <tr>
        <th rowspan="2">项目</th>
        <th rowspan="2">序号</th>
        <th rowspan="2">产品名称</th>
        <th rowspan="2">品牌</th>
        <th rowspan="2">型号规格材质</th>
        <th rowspan="2">单位</th>
        <th rowspan="2">工程量</th>
        <th colspan="3">单价</th>
        <th colspan="3">金额</th>
        <th rowspan="2">备 注</th>
      </tr>

      <tr>
        <th>成本</th>
        <th>经销商</th>
        <th>工程</th>
        <th>成本</th>
        <th>经销商</th>
        <th>工程指导</th>
      </tr>

      <template v-for="(group, index) in groupRows">
        <template v-for="(row, i) in group">
          <tbody class="td-list" :key="index + '_' + i">
            <template v-if="row.is_delete != 1">
              <tr class="total-td" :key="i" v-if="row.is_comb">
                <th colspan="4"></th>
                <th style="text-align: center" colspan="3">合计:</th>
                <th></th>
                <th></th>
                <th></th>
                <th>{{ row.total }}</th>
                <th>{{ row.total }}</th>
                <th>{{ row.total }}</th>
                <th></th>
              </tr>
              <tr v-else :key="i">
                <td>
                  {{ row['类别'] }}
                </td>
                <td>{{ row.index }}</td>
                <td>
                  <el-input
                    v-if="!isRead"
                    @change="changeData"
                    style="width: 120px"
                    size="mini"
                    v-model="row['产品名称']"
                  />
                  <template v-else>
                    {{ row['产品名称'] }}
                  </template>
                </td>
                <td>
                  <el-input
                    v-if="!isRead"
                    @change="changeData"
                    style="width: 80px"
                    size="mini"
                    v-model="row['品牌']"
                  />
                  <template v-else>
                    {{ row['品牌'] }}
                  </template>
                </td>
                <td>
                  <el-input
                    v-if="!isRead"
                    @change="changeData"
                    style="width: 140px"
                    size="mini"
                    v-model="row['厂商编号']"
                  />
                  <template v-else>
                    {{ row['厂商编号'] }}
                  </template>
                </td>
                <td>
                  <el-input
                    v-if="!isRead"
                    @change="changeData"
                    style="width: 45px"
                    size="mini"
                    v-model="row['标品单位']"
                  />
                  <template v-else>
                    {{ row['标品单位'] }}
                  </template>
                </td>
                <td>
                  <!-- <el-input
                                        @change="changeData"
                                        style="width: 50px"
                                        size="mini"
                                        v-model="row['标品单位']"
                                    /> -->
                </td>
                <td>-</td>
                <td>-</td>
                <td>-</td>

                <td>-</td>
                <td>-</td>
                <td>-</td>

                <td style="position: relative">
                  <div class="floating-div">
                    <i @click="addRow(row)" class="icon el-icon-circle-plus-outline"></i>

                    <i
                      v-if="row.is_delete == 1"
                      @click="removeRow(row)"
                      class="icon el-icon-top-left"
                    />
                    <i v-else @click="removeRow(row)" class="icon el-icon-delete"></i>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </template>
    </table>
  </div>
</template>

<script>
const ProductNumber = '类别';
const NeedCount = '需求数量';

export default {
  props: {
    programme: {
      type: Object,
      default() {
        return {};
      },
    },
    table: {
      type: Object,
      default() {
        return {};
      },
    },
    rows: {
      type: Array,
      default() {
        return [];
      },
    },
    isRead: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    baseRow() {
      let baseRow = null;
      for (let row of this.editRows) {
        if (row.serial_number == 0) {
          baseRow = row;
        }
      }
      return baseRow;
    },
    groupRows() {
      let groupSum = {};
      let groupRows = {};
      for (let rowIndex in this.editRows) {
        let row = this.editRows[rowIndex];
        if (row[ProductNumber]) {
          let key = row[ProductNumber];
          if (!groupRows[key]) {
            groupRows[key] = [];
          }

          if (!groupSum[key]) {
            groupSum[key] = 0;
          }
          groupRows[key].push(row);
          groupSum[key] += parseInt(row[NeedCount]);
        }
      }

      for (let key in groupSum) {
        groupRows[key].push({
          id: 0,
          is_comb: true,
          is_delete: 0,
          total: 0 /*groupSum[key]*/,
        });
      }

      return groupRows;
    },
  },
  data() {
    return { programmeRows: null, editRows: [] };
  },
  watch: {
    rows: {
      handler() {
        this.editRows = [];
        this.programmeRows = this.rows;
        for (let rowIndex in this.programmeRows) {
          let row = this.programmeRows[rowIndex];
          let d = {
            serial_number: row.serial_number,
            id: row.id,
            index: rowIndex,
          };

          for (let o of row.prop) {
            if (o.Name == '截面尺寸') {
              try {
                let v = JSON.parse(o.Value);
                o.Value = v;
              } catch (ex) {
                console.log(ex);
              }
            }
            d[o.Name] = o.Value;
          }

          this.editRows.push(d);
        }
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    addRow(row) {
      let newRow = {};
      for (let k in row) {
        if (k == ProductNumber) {
          newRow[k] = row[k];
        } else {
          newRow[k] = '';
        }
      }
      newRow[NeedCount] = 0;
      newRow['serial_number'] = this.editRows.length + 1;
      newRow['id'] = 0;
      newRow['index'] = this.editRows.length;

      this.editRows.push(newRow);
      this.$emit('changeData', this.editRows);
    },
    removeRow(row) {
      let index = parseInt(row.index);

      if (row.id == 0) {
        this.editRows.splice(index, 1);
      } else {
        row.is_delete = 1;

        this.$set(this.editRows[index], row);
        this.editRows[index]['is_delete'] = 1;
      }
      this.$emit('changeData', this.editRows);
    },
    changeData() {
      // console.log(arguments);
      this.$emit('changeData', this.editRows);
    },
  },
};
</script>

<style lang="scss" scoped></style>
