<template>
  <div v-if="table">
    <div ref="export">
      <base-table
        @changeData="changeData"
        v-if="table.project_type == 1 && table.table_type == 1"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <base-table
        @changeData="changeData"
        v-if="table.project_type == 3 && table.table_type == 1"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <base-table-list
        @changeData="changeData"
        v-else-if="table.project_type == 3 && table.table_type == 2"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <base-table
        @changeData="changeData"
        v-if="table.project_type == 4 && table.table_type == 1"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <base-table-list
        @changeData="changeData"
        v-else-if="table.project_type == 4 && table.table_type == 2"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <wall-table
        @changeData="changeData"
        v-if="table.project_type == 5 && table.table_type == 1"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <wall-list-table
        @changeData="changeData"
        v-if="table.project_type == 5 && table.table_type == 2"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <wall-list2-table
        @changeData="changeData"
        v-if="table.project_type == 5 && table.table_type == 3"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <base-table-list
        @changeData="changeData"
        v-else-if="table.project_type == 1 && table.table_type == 2"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <base-table-list
        @changeData="changeData"
        v-else-if="table.project_type == 1 && table.table_type == 3"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <bathroom-table
        @changeData="changeData"
        v-else-if="table.project_type == 6"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <water-table
        @changeData="changeData"
        v-else-if="table.project_type == 12"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />
      <water-table
        @changeData="changeData"
        v-else-if="table.project_type == 2"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />
      <water-table
        @changeData="changeData"
        v-else-if="table.project_type == 7"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <simple-table
        @changeData="changeData"
        v-if="table.project_type == 8"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <simple-table
        @changeData="changeData"
        v-if="table.project_type == 9"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <simple-table
        @changeData="changeData"
        v-if="table.project_type == 10"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <simple-table
        @changeData="changeData"
        v-if="table.project_type == 15 && (table.table_type == 1 || table.table_type == 2)"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />

      <simple-table
        @changeData="changeData"
        v-if="table.project_type == 14 || table.project_type == 13"
        :programme="programme"
        :table="table"
        :rows="rows"
        :isRead="isRead"
      />
    </div>

    <div class="pagination" v-if="paginate.last_page > 1">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="paginate.current_page"
        :page-size="paginate.per_page"
        layout="total, prev, pager, next, jumper"
        :total="paginate.total"
      ></el-pagination>
    </div>

    <div class="footbar">
      <el-button size="small" round @click="back">返回</el-button>
      <el-button size="small" round @click="exportXlsx()" type="success">导出</el-button>
      <el-button
        v-if="!isLock && table.project_type != 15"
        size="small"
        round
        @click="save"
        type="primary"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import baseTable from './baseTable.vue';
import bathroomTable from './bathroomTable.vue';
import baseTableList from './baseTableList.vue';
import waterTable from './waterTable.vue';
import wallTable from './wallTable.vue';
import wallListTable from './wallListTable.vue';
import wallList2Table from './wallList2Table.vue';
import simpleTable from './simpleTable.vue';

import dayjs from 'dayjs';

import { programmeSave, programmeGetRows, programmeExport } from '@/common/programme.js';

export default {
  components: {
    baseTable,
    bathroomTable,
    baseTableList,
    waterTable,
    wallTable,
    wallListTable,
    wallList2Table,
    simpleTable,
  },
  props: {
    table: {
      type: Object,
      default() {
        return {};
      },
    },
    isLock: {
      type: Boolean,
      default: false,
    },
    // rows: {
    //     type: Array,
    //     default() {
    //         return [];
    //     },
    // },
    programme: {
      type: Object,
      default() {
        return {};
      },
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      rows: [],
      editRows: null,
      isRead: this.isLock,
      paginate: {
        current_page: 1,
        last_page: 1,
        per_page: 200,
        total: 0,
      },
    };
  },
  async created() {
    this.load(this.paginate.current_page);
  },
  methods: {
    async handleCurrentChange(val) {
      console.log(val);
      await this.load(val);
    },
    async load(page = 1) {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.2)',
      });
      let resp = await programmeGetRows({
        id: this.programme.id,
        project_type: this.table.project_type,
        table_type: this.table.table_type,
        table_name: this.table.table_name,
        page: page,
      });

      this.rows = resp.result.rows;
      this.paginate = {
        current_page: resp.result.current_page,
        last_page: resp.result.last_page,
        per_page: resp.result.per_page,
        total: resp.result.total,
      };
      loading.close();
    },
    back() {
      if (this.editRows !== null) {
        this.$confirm('您有修改数据, 直接返回将丢失, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$router.go(-1);
          // this.$router.push('/programme');
        });
      } else {
        this.$router.go(-1);
        // this.$router.push('/programme');
      }
    },
    async save() {
      if (this.editRows !== null) {
        // for (let row of this.editRows) {
        //     for (let key in row) {
        //         let val = row[key];
        //         if (key == '利用率') {
        //             row[key] = parseFloat(val / 100);
        //         }
        //     }
        // }
        let params = {
          id: this.id,
          project_type: this.table.project_type,
          table_name: this.table.table_name,
          table_type: this.table.table_type,
          json: JSON.stringify(this.editRows),
        };
        let resp = await programmeSave(params);
        if (resp.code == 200) {
          this.$alert('保存成功', '提示', {
            confirmButtonText: '确定',
          });
        }
      } else {
        this.$message({
          type: 'info',
          message: '您未修改数据, 无需保存. ',
        });
      }
    },
    exportXlsx() {
      // if (this.paginate.last_page > 1) {
      //window.sessionStorage.getItem('token');
      // window.open('');
      let params = {
        id: this.programme.id,
        project_type: this.table.project_type,
        table_type: this.table.table_type,
        table_name: this.table.table_name,
      };
      programmeExport(params, 'export_' + dayjs().format('YYYYMMDDTHHmmss') + '.xlsx');
      // } else {
      //     this.isRead = true;
      //     this.$nextTick(() => {
      //         const wb = XLSX.utils.table_to_book(this.$refs['export'], {
      //             raw: true,
      //         });

      //         const wbout = XLSX.write(wb, {
      //             bookType: 'xlsx',
      //             bookSST: true,
      //             type: 'array',
      //             cellStyles: true,
      //             showGridLines: true,
      //         });

      //         try {
      //             FileSaver.saveAs(
      //                 new Blob([wbout], {
      //                     type: 'application/octet-stream',
      //                 }),
      //                 'export_' + dayjs().format('YYYYMMDDTHHmmss') + '.xlsx',
      //             );
      //             this.isRead = false;
      //         } catch (e) {
      //             console.log(e);
      //         }
      //     });
      // }
    },
    changeData(val) {
      this.editRows = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  // position: fixed;
  // top: 230px;
  // left: 260px;
}
.footbar {
  margin: 8px;
  padding: 16px 0;
  text-align: center;
}
</style>
