<template>
  <div>
    <el-form>
      <table class="tableContainer">
        <caption>{{ programme.project_name }}</caption>
        <tr>
          <th colspan="2">工程信息:</th>
          <th colspan="7">{{ programme.project_id }}</th>
          <th colspan="3">销售单编号:</th>
          <th colspan="3">下单时间:</th>
        </tr>

        <tr>
          <th colspan="2">工程地址:</th>
          <td colspan="7">&nbsp;</td>
          <td colspan="3" rowspan="2"></td>
          <td rowspan="2" colspan="3">{{ programme.created_at | formatDate }}</td>
        </tr>

        <tr>
          <th colspan="2">联系人:</th>
          <td colspan="2">&nbsp;</td>
          <th colspan="2">联系方式:</th>
          <td colspan="3"></td>
        </tr>

        <tr>
          <td colspan="15">&nbsp;</td>
        </tr>

        <tr>
          <th rowspan="2">序号</th>
          <th rowspan="2">类别</th>
          <th rowspan="2">品牌</th>
          <th rowspan="2">产品名称</th>
          <th rowspan="2">产品编号</th>
          <th colspan="3">标品规格(mm)</th>
          <th colspan="2">需求量</th>
          <th colspan="2">标品</th>
          <th rowspan="2">利用率</th>
          <th rowspan="2">进场时间</th>
          <th rowspan="2">备注</th>
        </tr>

        <tr>
          <th colspan="2">截面尺寸</th>
          <th>长</th>
          <th>单位</th>
          <th>数量</th>
          <th>单位</th>
          <th>数量</th>
        </tr>

        <template v-for="(group, index) in groupRows">
          <template v-for="(row, i) in group">
            <tbody class="td-list" :key="index + '_' + i">
              <template v-if="row.is_delete != 1">
                <tr class="total-td" :key="i" v-if="row.is_comb">
                  <th colspan="4"></th>
                  <th style="text-align: right" colspan="7">墙板总利用率 :</th>
                  <th></th>
                  <th colspan="3" style="text-align: left">
                    {{
                      parseFloat(
                        (row.total.need_unit / (row.total.use_unit / 1000000)) * 100,
                      ).toFixed(2)
                    }}%
                  </th>
                </tr>
                <tr v-else :key="i">
                  <td>{{ row.serial_number }}</td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 140px"
                      size="mini"
                      v-model="row['类别']"
                    />
                    <template v-else>
                      {{ row['类别'] }}
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 120px"
                      size="mini"
                      v-model="row['品牌']"
                    />
                    <template v-else>
                      {{ row['品牌'] }}
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 140px"
                      size="mini"
                      v-model="row['产品名称']"
                    />
                    <template v-else>
                      {{ row['产品名称'] }}
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 160px"
                      size="mini"
                      v-model="row['厂商编号']"
                    />
                    <template v-else>
                      {{ row['厂商编号'] }}
                    </template>
                  </td>
                  <template v-if="row['截面尺寸']">
                    <td colspan="2">
                      <el-input
                        v-if="!isRead"
                        @change="changeData"
                        style="width: 120px"
                        size="mini"
                        v-model="row['截面尺寸']"
                      />
                      <template v-else>
                        {{ row['截面尺寸'] }}
                      </template>
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <el-input
                        v-if="!isRead"
                        @change="changeData"
                        style="width: 50px"
                        size="mini"
                        v-model="row['厚']"
                      />
                      <template v-else>
                        {{ row['厚'] }}
                      </template>
                    </td>
                    <td>
                      <el-input
                        v-if="!isRead"
                        @change="changeData"
                        style="width: 50px"
                        size="mini"
                        v-model="row['宽']"
                      />
                      <template v-else>
                        {{ row['宽'] }}
                      </template>
                    </td>
                  </template>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 60px"
                      size="mini"
                      v-model="row['长']"
                    />
                    <template v-else>
                      {{ row['长'] }}
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 60px"
                      size="mini"
                      v-model="row['需求单位']"
                    />
                    <template v-else>
                      {{ row['需求单位'] }}
                    </template>
                  </td>
                  <td>
                    <template v-if="!isRead">
                      <el-input
                        v-if="row['_NeedSize']"
                        @change="changeData"
                        style="width: 60px"
                        size="mini"
                        v-model="row['_NeedSize']"
                      />
                      <el-input
                        v-else
                        @change="changeData"
                        style="width: 60px"
                        size="mini"
                        v-model="row['需求数量']"
                      />
                    </template>

                    <template v-else>
                      <template v-if="row['_NeedSize']">
                        {{ row['_NeedSize'] }}
                      </template>
                      <template>
                        {{ row['需求数量'] }}
                      </template>
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 60px"
                      size="mini"
                      v-model="row['标品单位']"
                    />
                    <template v-else>
                      {{ row['标品单位'] }}
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 60px"
                      size="mini"
                      v-model="row['标品数量']"
                    />
                    <template v-else>
                      {{ row['标品数量'] }}
                    </template>
                  </td>
                  <td>
                    <el-input
                      v-if="!isRead"
                      @change="changeData"
                      style="width: 60px"
                      size="mini"
                      v-model="row['利用率']"
                    />

                    <template v-else>{{ row['利用率'] }}</template>

                    %
                  </td>

                  <td></td>

                  <td style="position: relative">
                    <div class="floating-div">
                      <i @click="addRow" class="icon el-icon-circle-plus-outline"></i>

                      <i
                        v-if="row.is_delete == 1"
                        @click="removeRow(index)"
                        class="icon el-icon-top-left"
                      />
                      <i v-else @click="removeRow(index)" class="icon el-icon-delete"></i>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </template>

        <tr>
          <td colspan="15">&nbsp;</td>
        </tr>

        <tr>
          <th colspan="3">制单人（签字）：</th>
          <th colspan="4"></th>
          <th colspan="2">审核：</th>
          <th colspan="6"></th>
        </tr>
      </table>
    </el-form>
  </div>
</template>

<script>
const ProductNumber = '类别';
const NeedCount = '_NeedSize';

export default {
  props: {
    programme: {
      type: Object,
      default() {
        return {};
      },
    },
    table: {
      type: Object,
      default() {
        return {};
      },
    },
    rows: {
      type: Array,
      default() {
        return [];
      },
    },
    isRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      programmeRows: null,
      editRows: [],
    };
  },
  computed: {
    groupRows() {
      let groupSum = {};
      let groupRows = {};
      for (let rowIndex in this.editRows) {
        let row = this.editRows[rowIndex];
        if (!groupRows[row[ProductNumber]]) {
          groupRows[row[ProductNumber]] = [];
        }
        groupRows[row[ProductNumber]].push(row);

        if (row[ProductNumber] == '墙板（墙面）') {
          if (!groupSum[row[ProductNumber]]) {
            groupSum[row[ProductNumber]] = {
              need_unit: 0,
              use_unit: 0,
            };
          }
          if (row['长'] * row['宽'] != 0) {
            groupSum[row[ProductNumber]]['need_unit'] += parseFloat(row[NeedCount]);
            groupSum[row[ProductNumber]]['use_unit'] += parseFloat(
              row['长'] * row['宽'] * row['标品数量'],
            );
          }
        }
      }

      for (let key in groupSum) {
        groupRows[key].push({ id: 0, is_comb: true, is_delete: 0, total: groupSum[key] });
      }

      return groupRows;
    },
  },
  watch: {
    rows: {
      handler() {
        this.editRows = [];
        this.programmeRows = this.rows;
        for (let rowIndex in this.programmeRows) {
          let row = this.programmeRows[rowIndex];
          try {
            // console.log(row);
            let d = {
              serial_number: row.serial_number,
              id: row.id,
              index: rowIndex,
            };

            for (let o of row.prop) {
              // if (o.Name == '利用率') {
              //     d[o.Name] = parseFloat(o.Value) * 100;
              // } else {
              d[o.Name] = o.Value;
              // }
            }

            this.editRows.push(d);
          } catch (e) {
            console.log(row);
          }
        }
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.programmeRows = this.rows;
    for (let rowIndex in this.programmeRows) {
      let row = this.programmeRows[rowIndex];
      try {
        // console.log(row);
        let d = {
          serial_number: row.serial_number,
          id: row.id,
          index: rowIndex,
        };

        for (let o of row.prop) {
          // if (o.Name == '利用率') {
          //     d[o.Name] = parseFloat(o.Value) * 100;
          // } else {
          d[o.Name] = o.Value;
          // }
        }

        this.editRows.push(d);
      } catch (e) {
        console.log(row);
      }
    }
  },
  methods: {
    addRow() {
      let row = this.editRows[0];
      let newRow = {};
      for (let k in row) {
        newRow[k] = '';
      }

      newRow['serial_number'] = this.editRows.length + 1;
      newRow['id'] = 0;
      this.editRows.push(newRow);
      this.$emit('changeData', this.editRows);
    },
    removeRow(index) {
      let row = this.editRows[index];
      if (row.id == 0) {
        this.editRows.splice(index, 1);
      } else {
        row.is_delete = 1;

        this.$set(this.editRows[index], row);
        this.editRows[index]['is_delete'] = 1;
      }
      this.$emit('changeData', this.editRows);
    },
    changeData() {
      this.$emit('changeData', this.editRows);
    },
  },
};
</script>

<style lang="scss" scoped></style>
