<template>
  <div>
    <el-form>
      <table class="tableContainer" v-if="editRows">
        <caption>{{ programme.project_name }}</caption>

        <tr>
          <template v-for="(row, index) in editRows[0]">
            <th class="td-list" v-if="index != 'id'" :key="index">
              {{ index == 'serial_number' ? '序号' : index }}
            </th>
          </template>
        </tr>

        <tbody class="td-list" v-for="(row, index) in editRows" :key="index">
          <tr v-if="row.is_delete != 1">
            <template v-for="(val, field) in row">
              <td v-if="field != 'id'" :key="field">
                <template v-if="typeof val === 'number' && Number.isFinite(val) && val % 1 !== 0">
                  {{ val.toFixed(2) }}
                </template>

                <template
                  v-else-if="
                    typeof val == 'string' && parseFloat(val) == val && val.indexOf('.') != -1
                  "
                >
                  {{ parseFloat(val).toFixed(2) }}
                </template>
                <template v-else>{{ val }}</template>
              </td>
            </template>
            <!-- <td>{{ row.serial_number }}</td>
                        <td>
                            <el-input
                                v-if="!isRead"
                                @change="changeData"
                                style="width: 140px"
                                size="mini"
                                v-model="row['类别']"
                            />
                            <template v-else>
                                {{ row['类别'] }}
                            </template>
                        </td>
                        <td>
                            <el-input
                                v-if="!isRead"
                                @change="changeData"
                                style="width: 120px"
                                size="mini"
                                v-model="row['品牌']"
                            />
                            <template v-else>
                                {{ row['品牌'] }}
                            </template>
                        </td>
                        <td>
                            <el-input
                                v-if="!isRead"
                                @change="changeData"
                                style="width: 140px"
                                size="mini"
                                v-model="row['产品名称']"
                            />
                            <template v-else>
                                {{ row['产品名称'] }}
                            </template>
                        </td>
                        <td>
                            <el-input
                                v-if="!isRead"
                                @change="changeData"
                                style="width: 160px"
                                size="mini"
                                v-model="row['厂商编号']"
                            />
                            <template v-else>
                                {{ row['厂商编号'] }}
                            </template>
                        </td>
                        <template v-if="row['截面尺寸']">
                            <td colspan="2">
                                <el-input
                                    v-if="!isRead"
                                    @change="changeData"
                                    style="width: 120px"
                                    size="mini"
                                    v-model="row['截面尺寸']"
                                />
                                <template v-else>
                                    {{ row['截面尺寸'] }}
                                </template>
                            </td>
                        </template>
                        <template v-else>
                            <td v-if="row['高']">
                                <el-input
                                    v-if="!isRead"
                                    @change="changeData"
                                    style="width: 50px"
                                    size="mini"
                                    v-model="row['高']"
                                />
                                <template v-else>
                                    {{ row['高'] }}
                                </template>
                            </td>

                            <td v-else>
                                <el-input
                                    v-if="!isRead"
                                    @change="changeData"
                                    style="width: 50px"
                                    size="mini"
                                    v-model="row['厚']"
                                />
                                <template v-else>
                                    {{ row['厚'] }}
                                </template>
                            </td>

                            <td>
                                <el-input
                                    v-if="!isRead"
                                    @change="changeData"
                                    style="width: 50px"
                                    size="mini"
                                    v-model="row['宽']"
                                />
                                <template v-else>
                                    {{ row['宽'] }}
                                </template>
                            </td>
                        </template>
                        <td>
                            <el-input
                                v-if="!isRead"
                                @change="changeData"
                                style="width: 60px"
                                size="mini"
                                v-model="row['长']"
                            />
                            <template v-else>
                                {{ row['长'] }}
                            </template>
                        </td>
                        <td>
                            <el-input
                                v-if="!isRead"
                                @change="changeData"
                                style="width: 60px"
                                size="mini"
                                v-model="row['需求单位']"
                            />
                            <template v-else>
                                {{ row['需求单位'] }}
                            </template>
                        </td>
                        <td>
                            <template v-if="!isRead">
                                <el-input
                                    v-if="row['NeedSize']"
                                    @change="changeData"
                                    style="width: 60px"
                                    size="mini"
                                    v-model="row['NeedSize']"
                                />
                                <el-input
                                    v-else
                                    @change="changeData"
                                    style="width: 60px"
                                    size="mini"
                                    v-model="row['需求数量']"
                                />
                            </template>

                            <template v-else>
                                <template v-if="row['NeedSize']">
                                    {{ row['NeedSize'] }}
                                </template>
                                <template>
                                    {{ row['需求数量'] }}
                                </template>
                            </template>
                        </td>
                        <td>
                            <el-input
                                v-if="!isRead"
                                @change="changeData"
                                style="width: 60px"
                                size="mini"
                                v-model="row['标品单位']"
                            />
                            <template v-else>
                                {{ row['标品单位'] }}
                            </template>
                        </td>
                        <td>
                            <el-input
                                v-if="!isRead"
                                @change="changeData"
                                style="width: 60px"
                                size="mini"
                                v-model="row['标品数量']"
                            />
                            <template v-else>
                                {{ row['标品数量'] }}
                            </template>
                        </td>
                        <td>
                            <el-input
                                v-if="!isRead"
                                @change="changeData"
                                style="width: 60px"
                                size="mini"
                                v-model="row['利用率']"
                            />
                            <template v-else>
                                {{ row['利用率'] }}
                            </template>
                        </td>

                        <td></td>

                        <td style="position: relative">
                            <div class="floating-div">
                                <i @click="addRow" class="icon el-icon-circle-plus-outline"></i>

                                <i
                                    v-if="row.is_delete == 1"
                                    @click="removeRow(index)"
                                    class="icon el-icon-top-left"
                                />
                                <i v-else @click="removeRow(index)" class="icon el-icon-delete"></i>
                            </div>
                        </td> -->
          </tr>
        </tbody>
      </table>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    programme: {
      type: Object,
      default() {
        return {};
      },
    },
    table: {
      type: Object,
      default() {
        return {};
      },
    },
    rows: {
      type: Array,
      default() {
        return [];
      },
    },
    isRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editRows: [],
    };
  },
  watch: {
    rows: {
      handler(newval) {
        // console.error(newval.length);
        if (newval) {
          this.editRows = [];
          // ~ 
          for (let index in this.rows) {
            let row = this.rows[index];
            let d = {
              serial_number: row.serial_number,
              id: row.id,
            };

            for (let o of row.prop) {
              d[o.Name] = o.Value;
            }

            this.editRows.push(d);
          }
        }
      },
      deep: true,
      immediate: false,
    },
  },
  // created() {
  //     for (let row of this.rows) {
  //         let d = {
  //             serial_number: row.serial_number,
  //             id: row.id,
  //         };

  //         for (let o of row.prop) {
  //             d[o.Name] = o.Value;
  //         }

  //         this.editRows.push(d);
  //     }
  // },
  methods: {
    addRow() {
      let row = this.editRows[0];
      // console.log(row);
      let newRow = {};
      for (let k in row) {
        newRow[k] = '';
      }
      newRow['serial_number'] = this.editRows.length + 1;
      newRow['id'] = 0;
      this.editRows.push(newRow);
      this.$emit('changeData', this.editRows);
    },
    removeRow(index) {
      let row = this.editRows[index];
      if (row.id == 0) {
        this.editRows.splice(index, 1);
      } else {
        row.is_delete = 1;

        this.$set(this.editRows[index], row);
        this.editRows[index]['is_delete'] = 1;
      }
      this.$emit('changeData', this.editRows);
    },
    changeData() {
      // console.log(arguments);
      this.$emit('changeData', this.editRows);
    },
  },
};
</script>

<style lang="scss" scoped>
.tableContainer {
  width: 98%;
  margin: 0 auto;
}
</style>
